import React from 'react'
import PropTypes from 'prop-types' 
import { graphql, Link, navigate }  from 'gatsby'
import { Scrollbars } from 'react-custom-scrollbars'
import MediaQuery from 'react-responsive'
import { css } from '@emotion/core'

import Img from '../components/AtomImg'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Text from '../components/Text'
import ds from '../theme'
import Article from '../components/Article'

// import { Container } from '../components/styles/Layout.styles'
import Carousel from '../components/Carousel'
import H4 from './../components/H4'
import Body2 from './../components/Body2'
import Div from './../components/Div'
import H6 from './../components/H6'
import Button from './../components/Button'
import Hr from '../components/Hr'
import H3 from './../components/H3'
import { mq } from './../components/styles/Layout.styles'
import { 
  h4Style,
  h2Style,
  item,
  imgHomeBox,
  solutionContent,
  linkCard
} from '../components/styles/InternalPage.styles'
import Solution from '../components/Solution'
import { withSolutions } from '../components/SolutionProvider'

import Parvi from '../images/clients/grupo-parvi.jpg'
import Aiam from '../images/clients/aiam.png'
import Basis from '../images/clients/basis.jpg'
import BateriasMoura from '../images/clients/baterias-moura.png'
import Bentonisa from '../images/clients/bentonisa.jpg'
import Brasimpex from '../images/clients/brasimpex .jpg'
import Britania from '../images/clients/britania.png'
import CamaraJp from '../images/clients/camara-jp.png'
import CartoriaAzevedoBastos from '../images/clients/cartorio-azevedo-bastos.png'
import Casatudo from '../images/clients/casatudo.png'
import Cbatech from '../images/clients/cbatech.png'
import ChilliBeans from '../images/clients/chilli-beans.png'
import CienciasMedicasPb from '../images/clients/ciencias-medicas-pb.png'
import ClubeTurismo from '../images/clients/clube-turismo.jpg'
import CoifeOdonto from '../images/clients/coife-odonto.png'
import ColengioAntares from '../images/clients/colegio-antares.jpg'
import Conductor from '../images/clients/conductor.png'
import Contreina from '../images/clients/contreina .jpg'
import DbDireito from '../images/clients/db-direto.jpg'
import DiretrixOn from '../images/clients/diretrix-on.jpg'
import Ecologica from '../images/clients/ecologica.png'
import EGen from '../images/clients/e-gen.jpg'
import EleveHost from '../images/clients/eleva-host.png'
import Elizabeth from '../images/clients/elizabeth.png'
import Equilibrio from '../images/clients/equilibrio.png'
import FaculdadeCescage from '../images/clients/faculdade-cescage.jpg'
import FaculdadeFutura from '../images/clients/faculdade-futura.jpg'
import FaculdadeSouza from '../images/clients/faculdade-souza.png'
import FaculdadeUnicampo from '../images/clients/faculdade-unicampo.jpg'
import Fan from '../images/clients/fan.png'
import Fps from '../images/clients/fps.png'
import GrupoAssesi from '../images/clients/grupo-assesi.jpg'
import GrupoHorizonteOriginal from '../images/clients/grupo-horizonte-original.png'
import HospitalMemorialSF from '../images/clients/hospital-memorial-sao-francisco.png'
import HospitalRegionalDeFranca from '../images/clients/hospital-regional-de-franca.jpg'
import Iland from '../images/clients/iland.png'
import InstitutoAdvogados from '../images/clients/instituto-advogados.png'
import Jotur from '../images/clients/jotur.jpg'
import K2Host from '../images/clients/k2host.png'
import KurierTec from '../images/clients/kurier-tecnologia.png'
import LinkSolution from '../images/clients/link-solutions.jpg'
import MarcosInacio from '../images/clients/marcos_inacio.png'
import Melita from '../images/clients/melita.png'
import MemorialDiagnostico from '../images/clients/memorial-diagnostico.png'
import NovaVidaTi from '../images/clients/nova-vida-ti.jpg'
import OAB from '../images/clients/oab.png'
import OABPe from '../images/clients/oab_pe.png'
import OmegaSistemas from '../images/clients/omega-sistemas.png'
import ReiAlimentos from '../images/clients/rei-alimentos.jpg'
import RogerioMenezesLeiloiro from '../images/clients/rogerio-menezes-leiloeiro.jpg'
import Roval from '../images/clients/roval.png'
import Sebrae from '../images/clients/sebrae.png'
import ServHost from '../images/clients/servhost.png'
import SescPb from '../images/clients/sesc-paraiba.png'
import Sintese from '../images/clients/sintese.png'
import SociedadeBrDeCbm from '../images/clients/socieade-brasileira-de-cbm.jpg'
import StaloDigital from '../images/clients/stalo-digital.jpg'
import SuporteOne from '../images/clients/suporte-one.jpg'
import TomoVale from '../images/clients/tomovale.jpg'
import Unifarma from '../images/clients/unifamma.png'
import Selfit from '../images/clients/selfit.png'

import Varejo from '../images/icon-varejo.svg'
import ServicosFinanceiros from '../images/icon-servicos-financeiro.svg'
import Ecommerce from '../images/icon-ecommerce.svg'
import Educacao from '../images/icon-educacao.svg'
import Governo from '../images/icon-governo.svg'
import Tecnlogia from '../images/icon-tecnologia.svg'
import Saude from '../images/icon-saude.svg'
import UltimasNovidades from '../images/ultimas-novidades.svg'
import PorqueHostdime from '../images/por-que-a-hostdime.svg'
import NossasInstalacoes from '../images/nossas-instalacoes.svg'

const clientsGrid = css(
  mq({
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: ['repeat(2, 104px)', 'repeat(2, 104px)', 'repeat(4, 120px)'],
    gridTemplateRows: ['repeat(2, 104px)', 'repeat(2, 104px)', 'repeat(2, 120px)'],
    overflow: 'hidden'
  })
)

const CardItemPorque = ({
  icon: Icon,
  src,
  title,
  cta,
}) => (
  <Div
    css={item}
    flexDirection={['column', 'column', 'row']}
    alignItems={['flex-start', 'flex-start', 'center']}
    p={[16, 16, '54px 54px 52px', '63px 64px 62px']}
    style={{
      backgroundColor: '#fafafa',
      borderRadius: 16
    }}
  >
    <Div
      mb={[6, 6, 0]}
      mr={[0, 0, 4]}
      width={['auto']}
    >
      {Icon && <Icon />}
      {src && <img
        src={src}
        css={imgHomeBox}
      />}
    </Div>
    <div
      style={{
        maxWidth: 152
      }}
    >
      <H6
        selected
        on='onSurface'
        variant='mediumEmphasis'
        mb={[2]}
        fontSize={[19.6, 19.6, 23.7, 28]}
        style={{
          fontWeight: 500
        }}
      >
        {title}
      </H6>
      <Link
        to='/por-que-a-hostdime'
        css={linkCard}
      >
        {cta}
      </Link>
    </div>
  </Div>
)

const CardItemEstrutura = ({
  icon: Icon,
  src,
  title,
  cta,
}) => (
  <Div
    css={item}
    flexDirection={['column', 'column', 'row']}
    alignItems={['flex-start', 'flex-start', 'center']}
    p={[16, 16, '54px 54px 52px', '63px 64px 62px']}
    style={{
      backgroundColor: '#fafafa',
      borderRadius: 16
    }}
  >
    <Div
      mb={[6, 6, 0]}
      mr={[0, 0, 4]}
      width={['auto']}
    >
      {Icon && <Icon />}
      {src && <img
        src={src}
        css={imgHomeBox}
      />}
    </Div>
    <div
      style={{
        maxWidth: 152
      }}
    >
      <H6
        selected
        on='onSurface'
        variant='mediumEmphasis'
        mb={[2]}
        fontSize={[19.6, 19.6, 23.7, 28]}
        style={{
          fontWeight: 500
        }}
      >
        {title}
      </H6>
      <Link
        to='/estrutura'
        css={linkCard}
      >
        {cta}
      </Link>
    </div>
  </Div>
)

const clients = [
  {
    name: 'Grupo Parvi',
    logo: Selfit
  },
  {
    name: 'Grupo Parvi',
    logo: Parvi
  },
  {
    name: 'Grupo Parvi',
    logo: Aiam
  },
  {
    name: 'Grupo Parvi',
    logo: Basis
  },
  {
    name: 'Grupo Parvi',
    logo: BateriasMoura
  },
  {
    name: 'Grupo Parvi',
    logo: Bentonisa
  },
  {
    name: 'Grupo Parvi',
    logo: Brasimpex
  },
  {
    name: 'Grupo Parvi',
    logo: Britania
  },
  {
    name: 'Grupo Parvi',
    logo: CamaraJp
  },
  {
    name: 'Grupo Parvi',
    logo: CartoriaAzevedoBastos
  },
  {
    name: 'Grupo Parvi',
    logo: Casatudo
  },
  {
    name: 'Grupo Parvi',
    logo: Cbatech
  },
  {
    name: 'Grupo Parvi',
    logo: ChilliBeans
  },
  {
    name: 'Grupo Parvi',
    logo: CienciasMedicasPb
  },
  {
    name: 'Grupo Parvi',
    logo: ClubeTurismo
  },
  {
    name: 'Grupo Parvi',
    logo: CoifeOdonto
  },
  {
    name: 'Grupo Parvi',
    logo: ColengioAntares
  },
  {
    name: 'Grupo Parvi',
    logo: Conductor
  },
  {
    name: 'Grupo Parvi',
    logo: Contreina
  },
  {
    name: 'Grupo Parvi',
    logo: DbDireito
  },
  {
    name: 'Grupo Parvi',
    logo: DiretrixOn
  },
  {
    name: 'Grupo Parvi',
    logo: Ecologica
  },
  {
    name: 'Grupo Parvi',
    logo: EGen
  },
  {
    name: 'Grupo Parvi',
    logo: EleveHost
  },
  {
    name: 'Grupo Parvi',
    logo: Elizabeth
  },
  {
    name: 'Grupo Parvi',
    logo: Equilibrio
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeCescage
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeFutura
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeSouza
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeUnicampo
  },
  {
    name: 'Grupo Parvi',
    logo: Fan
  },
  {
    name: 'Grupo Parvi',
    logo: Fps
  },
  {
    name: 'Grupo Parvi',
    logo: GrupoAssesi
  },
  {
    name: 'Grupo Parvi',
    logo: GrupoHorizonteOriginal
  },
  {
    name: 'Grupo Parvi',
    logo: HospitalMemorialSF
  },
  {
    name: 'Grupo Parvi',
    logo: HospitalRegionalDeFranca
  },
  {
    name: 'Grupo Parvi',
    logo: Iland
  },
  {
    name: 'Grupo Parvi',
    logo: InstitutoAdvogados
  },
  {
    name: 'Grupo Parvi',
    logo: Jotur
  },
  {
    name: 'Grupo Parvi',
    logo: K2Host
  },
  {
    name: 'Grupo Parvi',
    logo: KurierTec
  },
  {
    name: 'Grupo Parvi',
    logo: LinkSolution
  },
  {
    name: 'Grupo Parvi',
    logo: MarcosInacio
  },
  {
    name: 'Grupo Parvi',
    logo: Melita
  },
  {
    name: 'Grupo Parvi',
    logo: MemorialDiagnostico
  },
  {
    name: 'Grupo Parvi',
    logo: NovaVidaTi
  },
  {
    name: 'Grupo Parvi',
    logo: OAB
  },
  {
    name: 'Grupo Parvi',
    logo: OABPe
  },
  {
    name: 'Grupo Parvi',
    logo: OmegaSistemas
  },
  {
    name: 'Grupo Parvi',
    logo: ReiAlimentos
  },
  {
    name: 'Grupo Parvi',
    logo: RogerioMenezesLeiloiro
  },
  {
    name: 'Grupo Parvi',
    logo: Roval
  },
  {
    name: 'Grupo Parvi',
    logo: Sebrae
  },
  {
    name: 'Grupo Parvi',
    logo: ServHost
  },
  {
    name: 'Grupo Parvi',
    logo: SescPb
  },
  {
    name: 'Grupo Parvi',
    logo: Sintese
  },
  {
    name: 'Grupo Parvi',
    logo: SociedadeBrDeCbm
  },
  {
    name: 'Grupo Parvi',
    logo: StaloDigital
  },
  {
    name: 'Grupo Parvi',
    logo: SuporteOne
  },
  {
    name: 'Grupo Parvi',
    logo: TomoVale
  },
  {
    name: 'Grupo Parvi',
    logo: Unifarma
  }
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  const posts = data.allWordpressPost.edges
  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 80]}
            mt={[30, 60]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 366, 415]}
              >
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center', 'center', 'flex-start']}
                textAlign={['center', 'center', 'left']}
              >
                <H4
                  color='black'
                  variant='highEmphasis'
                  mb={[5, 5, 3]}
                  css={h4Style}
                >
                  Soluções globais<br />e personalizadas de<br />Data Center
                </H4>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[3]}
                  maxWidth={['323px', 328, 433, 433]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                {false && <Text
                  fontSize={[19]}
                  lineHeight={['32px']}
                  order={[1, 1, 2]}
                  style={{
                    fontWeight: '600',
                    letterSpacing: '0.24px',
                    color: '#212121'
                  }}
                  mb={[3, 3, 0]}
                >
                  Presente em 8 países<br /> Mais de 15 anos de experiência<br /> Mais de 50 mil clientes
                </Text>}
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                  maxWidth={['100%', '100%', 400, 400]}
                >
                  Somos um dos melhores data center do Brasil e também somos globais. Somos parceria, estrutura, inovação e disponibilidade para o seu negócio. Conheça nossas soluções e entre em contato para personalizá-las às suas necessidades.
                </Body2>
                <Button
                  onClick={() => navigate('/nossos-produtos')}
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                  }}
                >
                  nossos produtos
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Row
          mb={[50, 50, 100]}
          mx={[-16, -16, 0, 0]}
          maxWidth={['100%', 328, 1046]}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1/2]}
          >
            <Link
              to='/por-que-a-hostdime'
              style={{
                textDecoration: 'none'
              }}
            >
              <CardItemPorque
                src={PorqueHostdime}
                title='Por que a HostDime?'
                cta='Saiba aqui'
              />
            </Link>
          </Column>
          <Column
            width={[1/2]}
          >
            <Link
              to='/estrutura'
              style={{
                textDecoration: 'none'
              }}
            >
              <CardItemEstrutura
                src={NossasInstalacoes}
                title='Nossas instalações'
                cta='Conheça'
              />
            </Link>
          </Column>
        </Row>
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 50, 88, 100]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 395, 489]}
              >
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Cloud Server<br />HostDime
                </H3>
                {/* {false && <Div
                  css={[
                    (css`
                    animation: ${cloudAni} 10s 5s infinite linear,
                  `),
                  cloud
                  ]}
                />} */}
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  maxWidth={['323px', 416]}
                >
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                >
                  Servidores em nuvem flexíveis, que ajustam automaticamente a CPU, RAM e o espaço de disco sob demanda, apresentando alta disponibilidade e failover automático. Aproveite nosso hardware de ponta, incluindo processadores de nível empresarial e os melhores SSDs da indústria para criar sua própria estrutura instantânea de negócios.
                </Body2>
                <Button
                  onClick={() => navigate('/cloud-server')}
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                  }}
                >
                  Explorar opções de Nuvem
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 50, 88, 100]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 357, 416]}
              >
                <Img
                  fluid={data.image3.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Servidores<br />Dedicados
                </H3>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={['323px', 361, 469]}
                >
                  <Img
                    fluid={data.image3.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  align='center'
                  mb={[5]}
                >
                  Hardwares fisicamente isolados, de uso exclusivo da sua empresa, trazendo assim, maior segurança e desempenho, além de contar com monitoramento proativo 24x7x365.
                </Body2>
                <Button
                  onClick={() => navigate('/servidores-dedicados')}
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    display: 'flex',
                    margin: '0 auto'
                  }}
                >
                  configurações personalizadas
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 70]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 348, 418]}
              >
                <Img
                  fluid={data.image4.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Colocation<br />HostDime
                </H3>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={['323px', '323px', 348, 418]}
                >
                  <Img
                    fluid={data.image4.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                >
                  Abrigue seu parque de ativos de TI em nossas estruturas certificadas e garanta a continuidade do seu negócio.
                </Body2>
                <Button
                  onClick={() => navigate('/colocation')}
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    margin: '0 auto'
                  }}
                >
                  Saiba Mais
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 680]}
        >
          <Row
            pt={[50, 70]}
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <Div
                alignItems={['center']}
              >
                <H4
                  color='black'
                  variant='highEmphasis'
                  align='center'
                  mb={[5]}
                >
                  Temos mais de 50 mil clientes<br /> em todo o mundo
                </H4>
                <Div
                  mb={[4]}
                  maxWidth={[220, 220, 510]}
                >
                  <MediaQuery minDeviceWidth={840}>
                    {/* You can also use a function (render prop) as a child */}
                    {(matches) => {
                      const maxItems = matches ? 8 : 4
                      const pages = Math.ceil(clients.length / maxItems)
                      return (
                        <Carousel
                          open
                          mobileDots={!matches}
                        >
                          {Array(pages).fill(0).map((item, i) => i).map((page, i) => {
                            const min = (i * maxItems)
                            const max = (i * maxItems) + maxItems
                            return (
                              <Div
                                key={`carouselpage-${i}`}
                                css={clientsGrid}
                              >
                                {clients.slice(min, max).map((client, i) => (
                                  <>
                                    {client.logo
                                      ? (
                                        <div
                                          key={`carouselitem-${i}`}
                                          style={{
                                            width: '100%',
                                            height: '100%'
                                          }}
                                        >
                                          <img
                                            src={client.logo}
                                            alt={client.name}
                                            style={{
                                              objectFit: 'cover',
                                              width: '100%',
                                              height: '100%',
                                              lineHeight: 1
                                            }}
                                          />
                                        </div>
                                      )
                                      : (
                                        <div
                                          key={`carouselitem-${i}`}
                                          style={{
                                            height: '100%',
                                            backgroundColor: ds.brand('iceBlue')
                                          }}
                                        >
                                          {i + 1}
                                        </div>
                                      )
                                    }
                                  </>
                                ))}
                              </Div>
                            )
                          })}
                        </Carousel>
                      )}
                    }
                  </MediaQuery>
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  align='center'
                  mb={[5]}
                >
                  Organizações públicas e privadas, de todos os<br /> segmentos, confiam na HostDime.
                </Body2>
                <Button
                  onClick={() => navigate('/clientes')}
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    margin: '0 auto'
                  }}
                >
                  veja quem são nossos clientes
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Div
          alignItems={['center']}
          style={{
            backgroundColor: ds.brand('iceBlue')
          }}
          mx={[-16, -16, -24, -24]}
          width={[
            'calc(100% + 32px)',
            'calc(100% + 32px)',
            'calc(100% + 48px)'
          ]}
        >
          <Div
            maxWidth={['100%', 390, 680]}
          >
            <Row
              pt={[40, 70]}
              pb={[40, 70]}
              mx={[0, -16, -24, -24]}
            >
              <Column
                width={[1]}
              >
                <div>
                  <H4
                    color='black'
                    variant='highEmphasis'
                    align='center'
                    mb={[3]}
                  >
                    Soluções Personalizadas
                  </H4>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    align='center'
                    mb={[5]}
                  >
                    Nossa equipe de desenvolvimento de negócios escuta você, entende a sua estrutura e molda serviços sob medida para otimizar o seu setor de TI. O nosso maior objetivo é garantir a segurança de dados críticos para a sua empresa e soluções para permitir mais inovação no setor. Somos personalizados para sanar a sua dor.
                  </Body2>
                  <Div
                    alignItems={['center']}
                  >
                    <Div
                      maxWidth={['100%', 422, 680]}
                    >
                      <Solution
                        title='Serviços Financeiros'
                        id='finan'
                        src={ServicosFinanceiros}
                      >
                        <Scrollbars
                          style={{ height: 400 }}
                        >
                          <Div
                            css={solutionContent}
                          >
                            <Body2>O mercado financeiro é o que mais tem investido em segurança no Brasil. Segundo pesquisada Febraban, em 2016 os investimentos chegaram a 18,6 bilhões de dólares no país.Empresas desse setor precisam garantir ao consumidor final o fator segurança e por isso,esse investimento está em curva de ascensão constante.</Body2>
                            <Body2 style={{ fontWeight: 500 }}>Mas sabemos que não é só investir em segurança, é garantir a excelência na entrega dassoluções.</Body2>
                            <Body2>Sabe por que a HostDime Brasil é o parceiro ideal para o seu negócio? Além de segurançafísica e de soluções, nós oferecemos benefícios que fazem a diferença no fim de cadaoperação: flexibilidade, maior disponibilidade, alta conectividade, velocidade nas transaçõese eficiência operacional.</Body2>
                            <Body2>Se, além de uma segurança proativa e robusta, você:</Body2>
                            <Body2>
                              <ul>
                                <li>- Precisa de um processamento contínuo para as suas transações;</li>
                                <li>- Sabe que uma alta conectividade é essencial para estar sempre disponível;</li>
                                <li>- Necessita de uma alta capacidade de armazenamento e backup de informações;</li>
                                <li>- Entende que precisa otimizar e reduzir a latência (tempo de resposta) de suaatividade;</li>
                              </ul>
                            </Body2>
                            <Body2>Então é com nossas soluções que criará uma vantagem estratégica no mercado.</Body2>
                          </Div>
                        </Scrollbars>
                      </Solution>
                      <Solution
                        title='E-commerce'
                        id='ecom'
                        src={Ecommerce}
                      >
                        <Scrollbars
                          style={{ height: 400 }}
                        >
                          <Div
                            css={solutionContent}
                          >
                            <Body2>Com um comércio online, estar online 24 horas por dia, 7 dias da semana, 365 dias do ano é
                            o básico que precisa ser garantido para a sua empresa. Sem falar que todo empresário
                            digital deve ser multi: entender de vendas, comunicação e até de TI, se necessário.
                            A gente sabe que não é uma rotina fácil, e estamos aqui para dar o suporte necessário,
                            principalmente nas datas comemorativas que o seu negócio não pode sair do ar.</Body2>
                            <Body2>Se você já conta com uma pessoa de TI para gerenciar seus servidores e o seu negócio, nós
                            podemos ser parceiros dele com um suporte disponível 24 x 7 x 365, e produtos
                            personalizados para a sua empresa, mas tão personalizados que vão ser ideais para otimizar
                            seus custos com toda essa operação.</Body2>
                            <Body2>E, se você ainda não tem "o carinha da informática" para te auxiliar, além das soluções
                            personalizadas e suporte proativo, podemos agregar nos seus serviços um gerenciamento
                            full.</Body2>
                            <Body2>Tudo depende do que você precisar, quando precisar.
                            Se você quer disponível 100% do tempo para todos os seus clientes, sem precisar resolver
                            problemas simples de TI, então nós estamos aqui, 100% disponíveis e personalizados para
                            você.</Body2>
                          </Div>
                        </Scrollbars>
                      </Solution>
                      <Solution
                        title='Educação'
                        id='edu'
                        src={Educacao}
                      >
                        <Scrollbars
                          style={{ height: 400 }}
                        >
                          <Div
                            css={solutionContent}
                          >
                            <Body2>Um pensamento que ocorre constantemente quando citamos a junção de "tecnologia" e
                            "educação", é a oferta cada vez maior de cursos por Ensino à Distância (EaD). Mas é
                            essencial lembrar que a tecnologia já está muito bem estruturada em outras vertentes da
                            educação, seja por aulas mais interativas, através dos tablets de ensino, ou até mesmo pelo
                            sistema online das escolas e faculdades, em que são ofertadas matrículas e o serviço de
                            acompanhamento de todo o histórico de desempenho do aluno.</Body2>
                            <Body2>Para criar o ambiente propício na integração de sistemas, redes e na manutenção desses
                            dados, é necessário contar com um parceiro estratégico que garanta integridade de todos
                            esses dados e facilidade de acesso a eles.</Body2>
                            <Body2>Pensando nisso, é importante que no planejamento de sua instituição esteja previsto
                            soluções para lidar com:</Body2>
                            <Body2>
                              <ul>
                                <li>- Conectividade e disponibilidade de informação;</li>
                                <li>- Flexibilidade de recursos para lidar com a sazonalidade de acessos (período de matrículas ou finais de períodos);</li>
                                <li>- Manutenção de sistemas críticos de gestão (como ERP);</li>
                              </ul>
                            </Body2>
                            <Body2>Se identificou com tudo isso? Conte conosco para manter seu sistema ativo e seus recursos
                            flexíveis, otimizando seus custos com toda essa estrutura.</Body2>
                          </Div>
                        </Scrollbars>
                      </Solution>
                      <Solution
                        title='Governo'
                        id='gov'
                        src={Governo}
                      >
                        <Scrollbars
                          style={{ height: 400 }}
                        >
                          <Div
                            css={solutionContent}
                          >
                            <Body2>Em períodos de cortes do Governo e a obrigação de planejar com 1 ano de antecedência
                            todos os investimentos em TICs, é necessário aplicar verbas de forma inteligente e tentar
                            concentrar a maior parte de soluções necessárias de TI em um ambiente que ofereça melhor
                            custo/benefício para a instituição.</Body2>
                            <Body2>Somos ideais porque, além de infraestrutura robusta e segura, somos também solução,
                            suporte e otimização.</Body2>
                            <Body2>Não basta apenas armazenar dados críticos, mas também é necessário - através do
                            princípio da transparência - disponibilizá-los com rapidez e prontidão e incentivar, através
                            desta e de outras ações, a inovação do país não só no setor privado, como também em
                            nossos orgãos públicos.</Body2>
                            <Body2>Segundo o Secretário de Governo Digital, Luis Felipe Monteiro, a expectativa é que a
                            economia digital incremente até 5,7% no PIB. “ ​ Queremos desenvolver no país uma
                            economia digital pulsante, dinâmica e tratada de maneira responsável e cuidadosa,
                            protegendo a privacidade dos usuários”, completou. *</Body2>
                            <Body2>*informações retiradas de matéria de assessoria do Governo Federal
                            http://www.economia.gov.br/noticias/2019/02/secretario-de-governo-digital-destaca-relaca
                            o-direta-entre-transformacao-digital-e-avancos-sociais</Body2>
                          </Div>
                        </Scrollbars>
                      </Solution>
                      <Solution
                        title='Varejo'
                        id='var'
                        src={Varejo}
                      >
                        <Scrollbars
                          style={{ height: 400 }}
                        >
                          <Div
                            css={solutionContent}
                          >
                            <Body2>Quando o sistema local do varejo falha, o prejuízo é certo.</Body2>
                            <Body2>Imagine uma grande distribuidora sem controle de estoque, controle de faturamento e
                            despesa com fornecedores. A imagem quem vem à sua cabeça é caótica, né? Isso por que o
                            varejo é um dos setores que mais sentem o impacto da tecnologia para o desenvolvimento e
                            otimização de suas atividades.</Body2>
                            <Body2>Conectividade, disponibilidade e agilidade são um diferencial para o setor. E nós podemos
                            garantir um serviço que garanta tudo isso através de infraestrutura robusta e soluções
                            personalizadas para o seu negócio.</Body2>
                            <Body2>Nosso orçamento é consultivo: o time de desenvolvimento de negócios vai te escutar,
                            entender o que já existe de estrutura de TI em seu negócio e vai sugerir formas de
                            otimizá-la para garantir uma otimização de seus custos.</Body2>
                            <Body2>Temos desde a opção de Colocation, possibilitando alocar equipamentos para a nossa
                            estrutura e garantir uma vida útil maior de todo o equipamento investido. Temos também a
                            contratação de serviços dedicados, evitando a preocupação com reposição e manutenção de
                            hardwares e temos o serviço de cloud server, para garantir ainda mais flexibilidade para o
                            seu negócio.</Body2>
                            <Body2>Tudo isso com o suporte técnico verdadeiramente 24 x 7 x 365, acompanhando ativamente
                            as suas necessidades.</Body2>
                          </Div>
                        </Scrollbars>
                      </Solution>
                      <Solution
                        title='Saúde'
                        id='saud'
                        src={Saude}
                      >
                        <Scrollbars
                          style={{ height: 400 }}
                        >
                          <Div
                            css={solutionContent}
                          >
                            <Body2>Nossos servidores são um grande cérebro de operações e são, também, o coração de toda a inovação que a saúde tem abraçado.</Body2>
                            <Body2>Modernização da infraestrutura na saúde não está apenas nos equipamentos de exames de imagem ou facilidade nos processos laboratoriais.
                            Também está na adoção de prontuário eletrônico, investimentos em rede, virtualização e cada vez mais inovação nos processos.</Body2>
                            <Body2>A tecnologia tem sido um fator definitivo para reduzir custos das clínicas e hospitais, conectar médicos que buscam uma eficiência e
                            assertividade nos diagnósticos e para agilizar e humanizar o processo de atendimento ao paciente, que vai poder ter acesso a informações
                            de seus exames e a seu histórico médico com cada vez mais facilidade.</Body2>
                            <Body2>Somos pioneiros no desenvolvimento de soluções para o e-SUS, sistema de prontuários eletrônicos pelo serviço público de saúde. E somos
                            apaixonados por todas as possibilidades que a inclusão e familiarização da tecnologia na saúde podem trazer para beneficiar a todos:
                            desde gestores de clínicas, médicos até os pacientes.</Body2>
                            <Body2>Nossas soluções são como diagnósticos: investigadas, pensadas e arquitetadas de forma personalizada para cada caso.</Body2>
                          </Div>
                        </Scrollbars>
                      </Solution>
                      <Solution
                        title='Tecnologia'
                        id='tech'
                        src={Tecnlogia}
                      >
                        <Scrollbars
                          style={{ height: 400 }}
                        >
                          <Div
                            css={solutionContent}
                          >
                            <Body2>Para todos aqueles profissionais do setor de tecnologia que buscam terceirizar não apenas
                            infraestrutura, mas também soluções que vão otimizar o tempo gasto em resoluções
                            cotidianas de TI para aplicação de inovação e melhorias no setor, nós somos o parceiro
                            certo!</Body2>
                            <Body2>Queremos que você seja o protagonista das soluções inovadoras e para que isso aconteça,
                            somos o seu backup ideal: oferecemos uma infraestrutura robusta, flexível e disponível com
                            suporte 24 x 7 x 365.</Body2>
                            <Body2>Além disso, ainda possuímos soluções personalizadas. Entendemos, de forma consultiva, o
                            que já está disponível de equipamento, infraestrutura e solução e planejamos, juntos uma
                            mesclagem ou a transferência de serviços para a nossa estrutura.</Body2>
                            <Body2>Tudo pensando no que for melhor para você e seu bolso.</Body2>
                          </Div>
                        </Scrollbars>
                      </Solution>
                    </Div>
                  </Div>
                </div>
              </Column>
            </Row>
          </Div>
        </Div>
        <Div
          maxWidth={['100%', 390, 680]}
        >
          <Row
            pt={[50, 70]}
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <div>
                <H4
                  color='black'
                  variant='highEmphasis'
                  align='center'
                  mb={[4]}
                >
                  Nós queremos transformar <br />
                  a sua empresa
                </H4>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  align='center'
                  mb={[4]}
                >
                  Fale com um de nossos consultores e receba uma proposta personalizada de serviços para solucionar seus problemas com TI e otimizar os custos do seu negócio.
                </Body2>
                <Button
                  onClick={() => navigate('/contatos')}
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                  }}
                >
                  Fale com nossos consultores
                </Button>
              </div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 680]}
          alignItems={['center', 'center', 'stretch']}
        >
          <Row
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Div
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                maxWidth={['100%', 344, '100%']}
              >
                <img
                  src={UltimasNovidades} 
                  style={{
                    marginRight: ds.space(3),
                    width: '68px',
                    height: '68px'
                  }}
                />
                <H4
                  selected
                  on='onSurface'
                  variant='highEmphasis'
                >
                  Últimas Novidades
                </H4>
              </Div>
              {posts.length > 0
                && Array(Math.ceil(posts.length / 2)).slice(0, 2).fill(0).map((item, i) => {
                  return (
                    <Row
                      key={`post-row-${i}`}
                      flexDirection={['column', 'column', 'row']}
                      mt={[40]}
                      maxWidth={['100%', 344, '100%']}
                      width={['100%']}
                    >
                      {posts.slice(i === 0 ? 0 : i * 2, 2 + i * 2).map((post, x) => (
                        <Column
                          key={`post-item-${x + i}`}
                          width={[1, 1, 1/2]}
                          mb={[24, 24, 0]}
                        >
                          <Article
                            title={post.node.title}
                            slug={post.node.slug}
                            meta={{
                              date: post.node.date,
                              duration: '6 min'
                            }}
                            img={post.node.featured_media && post.node.featured_media.localFile.publicURL}
                          />
                          <Hr
                            lineColor='lightPeriwinkle'
                            maxWidth={[50]}
                            style={{
                              marginLeft: 0
                            }}
                          />
                        </Column>
                      ))}
                    </Row>
                  )
              })}
              <Button
                onClick={() => navigate('/blog')}
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['200px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  margin: '0 auto',
                  marginTop: 50
                }}
              >
                Ver Mais Artigos
              </Button>
            </Column>
          </Row>
        </Div>
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({ data }) => {
  return (
    <Layout>
      <Seo title='Soluções globais e personalizadas de Data Center' />
      <PageTemplate
        title='Soluções globais e personalizadas de Data Center'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment PostListFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "DD MM YYYY")
    title
    featured_media {
      localFile {
        publicURL
      }
    }
  }
  query HomeQuery {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    image1: file(relativePath: { eq: "home/solucoes-globais.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image2: file(relativePath: { eq: "home/cloud-server.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image3: file(relativePath: { eq: "home/servidor-dedicado.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image4: file(relativePath: { eq: "home/hostdime-colocation.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
